import React, { Component } from "react";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

class EvoPage extends Component {

  componentDidMount() {

    trackCustomEvent({
      category: "Conversion",
      action: "HelloFresh",
      label: "HelloFresh traffic source"
    });
  
    window.location.href="https://value.summon.com.au/?s=partner&d=hellofresh";
  }

  render() {
    return (
      <p>One moment...</p>
    );
  }
}
export default EvoPage;


